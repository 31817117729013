import React from 'react';
import * as S from './styles';
import DarkBlue from '../../../svg/Quote-Dark-Blue.inline.svg';
import DarkBlue2 from '../../../svg/Quote-Dark-Blue2.inline.svg';
import Green from '../../../svg/Quote-Green.inline.svg';
import MidBlue from '../../../svg/Quote-Mid-Blue.inline.svg';
import Orange from '../../../svg/Quote-Orange.inline.svg';
import Purple from '../../../svg/Quote-Purple.inline.svg';
import SkyBlue from '../../../svg/Quote-Sky-Blue.inline.svg';

const Review = ({ name, review, title, quoteColor, id }) => {
	return (
		<S.Wrapper id={id}>
			{quoteColor === 'DarkBlue' && <DarkBlue />}
			{quoteColor === 'DarkBlue2' && <DarkBlue2 />}
			{quoteColor === 'Green' && <Green />}
			{quoteColor === 'MidBlue' && <MidBlue />}
			{quoteColor === 'Orange' && <Orange />}
			{quoteColor === 'Purple' && <Purple />}
			{quoteColor === 'SkyBlue' && <SkyBlue />}

			<S.Review>“{review}”</S.Review>
			<S.ReviewerInfo>
				<S.Reviewer>{name}</S.Reviewer>
				<S.Designation>{title}</S.Designation>
			</S.ReviewerInfo>
		</S.Wrapper>
	);
};

export default Review;
