import styled from 'styled-components';
import {
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../../utils/helpers';

export const Wrapper = styled.div`
	padding: 0 15px;
`;

export const Review = styled.p`
	border: 0 !important;
	padding: 12px 0 10px 0 !important;
	font-family: ${setFontFamily.medium};
	margin: 0;
	line-height: 1.22;
	font-size: ${setFontSize.tiny};
	color: ${setColors.slateBlack};
	font-style: italic;
`;

export const ReviewerInfo = styled.div`
	padding-top: 10px;
	p {
		margin: 0;
	}
`;

export const Reviewer = styled.p`
	font-size: ${setFontSize.mediumTiny};
	font-family: ${setFontFamily.bold};
	color: ${setColors.lightBlack};
	line-height: 1.55;
`;

export const Designation = styled.p`
	color: ${setColors.lightBlack};
	font-family: ${setFontFamily.book}
	font-size: ${setFontSize.mediumTiny};
	line-height: 1.55;
`;
