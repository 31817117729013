import styled from 'styled-components';
import { mediaQuery, setColors } from '../../../../utils/helpers';

export const Wrapper = styled.section`
	background-color: ${setColors.lightestBlue};
	padding-top: 90px;
	padding-bottom: 200px;
	${mediaQuery.mobileXL`padding-bottom: 34px;`};
`;

export const SliderHolder = styled.div`
	display: none;
	.slick-arrow {
		display: none !important;
	}
	.slick-dots {
		margin-top: 20px;
		bottom: unset;
		${mediaQuery.mobileS`bottom: -40px;`};
		button {
			display: block;
			width: 10px;
			height: 10px;
			padding: 0;
			border-radius: 100%;
			border: 1px solid ${setColors.gainsBoro};
			text-indent: -9999px;
		}

		li.slick-active button {
			background: ${setColors.cyanBlue};
			border: none;
		}
	}
	${mediaQuery.mobileXL`display:block;`};
`;

export const ReviewHolder = styled.div`
	${mediaQuery.mobileXL`display:none`};
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 0;
	column-gap: 0px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	div:nth-child(even) {
		margin-top: 40px;
	}
`;
