import React from 'react';
import Slider from 'react-slick';
import Title from '../../global/title/Title';
import Review from '../review/Review';
import * as S from './styles';
import { Container } from '../../global/customers/styles';
import SlickCSS from '../../about-us-page/slick-css';

const ReviewHolder = ({ section4Reviews, section4Title }) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	return (
		<S.Wrapper>
			<SlickCSS />
			<Container>
				<Title title={section4Title} />
				<S.ReviewHolder style={{ marginTop: '45px' }}>
					{section4Reviews.reviews.map((review, id) => (
						<Review
							id={id}
							name={review.name}
							review={review.review}
							title={review.title}
							quoteColor={review.quoteColor}
						/>
					))}
				</S.ReviewHolder>

				<S.SliderHolder
					style={{ height: '280px', width: '100%', marginTop: '85px' }}
				>
					<Slider {...settings}>
						{section4Reviews.reviews.map((review, id) => (
							<Review
								id={id}
								name={review.name}
								review={review.review}
								title={review.title}
								quoteColor={review.quoteColor}
							/>
						))}
					</Slider>
				</S.SliderHolder>
			</Container>
		</S.Wrapper>
	);
};

export default ReviewHolder;
